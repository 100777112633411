import { navigate } from "gatsby"
import { isBrowser } from "../../../services/helper"

const IntegrationsHelp = () => {
    if(isBrowser()) {
        navigate(`/help`, { replace: true})
    }
    return null
}

export default IntegrationsHelp